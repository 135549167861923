import React from "react"
import Head from "../components/head"
import Layout from "../layouts/layout"
import { SectionHeader } from "../components/Common"
import CoolWordsCards from "../components/coolWords/CoolWordsCards"

function BlogPage() {
  return (
    <Layout>
      <Head title="Cool words from cool people" />
      <SectionHeader>Cool words from cool people</SectionHeader>
      <CoolWordsCards />
    </Layout>
  )
}

export default BlogPage
