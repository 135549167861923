import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import GrandQuote from "../mdx/GrandQuote"

const CoolCards = styled.div`
  display: grid;
  padding-top: 2rem;
  padding-bottom: 2rem;
  gap: 1.6rem;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
  grid-template-rows: masonry;
`
const CoolQuote = styled(GrandQuote)`
  font-family: "Work Sans", sans-serif;
  font-size: calc(var(--heading-5) - 8px);
  line-height: calc(var(--heading-5) * 1.2 - 8px);
  letter-spacing: 0.024em;
  margin-top: 0 !important;
  padding: 1rem 1rem 2rem 1rem;

`

const CoolCard = styled(motion.div)`
  will-change: transform;
  transition: transform 240ms ease-in-out;
  :hover {
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%));
    transform: translate(-2%, -2%);
    transition: transform 240ms ease-in-out;
  }
  display: flex;
  flex-direction: column;
  background: var(--color-background-48-dp-elevation);
  padding: 1rem;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  height: fit-content;
`

function CoolWordsCards() {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { type: { eq: "cool_words" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          frontmatter {
            words
            date
            author
            social
            avatar {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const cardVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,

      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  }
  return (
    <CoolCards className="full-bleed add-padding">
      {data.allMdx.nodes.map(coolWords => {
        return (
          <CoolCard
            key={coolWords.frontmatter.words}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.3 }}
            variants={cardVariants}
          >
            <CoolQuote>{coolWords.frontmatter.words}</CoolQuote>
          </CoolCard>
        )
      })}
    </CoolCards>
  )
}

export default CoolWordsCards
