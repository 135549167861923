import styled from "styled-components"

import Quotes from "../../../static/website_assets/Quotes.svg"

const GrandQuote = styled.blockquote`
  ::before {
    content: "";
    display: block;
    mask: url(${props => props.Icon || Quotes }) no-repeat center;
    mask-size: contain;
    height: 2rem;
    width: 2rem;
    max-height: min(8rem, calc(100vw / 4));
    /* max-width: min(8rem, calc(100vw / 4));; */
    background-color: var(--color-theme-accent);
    /* position: absolute; */
    z-index: -1;
    /* transform: translate(0px, -54px); */
    opacity: 0.5;
  }
  display: block;
  font-family: "Mosk Extra Bold", sans-serif;
  font-size: calc(var(--heading-3) - 4px);
  line-height: calc(var(--heading-3) * 1.2 - 4px);
  letter-spacing: 0.024em;
  margin-top: var(--safety-padding) !important;
  padding: 1rem 1rem 2rem 1rem;
  /* background-color: var(--color-background-24-dp-elevation); */
  border-radius: 1rem;
  border-left: 2px solid var(--color-theme-accent);
  z-index: 99;
`
export default GrandQuote
